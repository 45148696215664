
import React from 'react'

export default function Title({ children, className }) {
  return (
    <div className={`text-2xl font-normal ${className}`}>
      {children}
    </div>
  )
}
