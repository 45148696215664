import React, { useState } from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Button({ onClick, children, style, className, variant, disabled, Icon }) {
  const [loading, setLoading] = useState(false)
  const iconStyles = {
    default: 'stroke-1 mr-2 -ml-1',
    primary: 'mr-2 -ml-1',
    icon: 'stroke-2'
  }
  const styles = {
    default: `text-base font-normal py-2 px-6 bg-opacity-20`,
    primary: `text-xl font-normal py-2 px-8 bg-opacity-30`,
    secondary: `text-xs font-light py-1 px-3 bg-opacity-20`,
    icon: `h-8 w-8 font-light py-1 px-1 box-content bg-opacity-20`,
    smallIcon: `h-4 w-4 text-sm font-light py-1 px-1 box-content bg-opacity-20`
  }
  const disabledStyle = 'opacity-50'
  return (
    <button
      onClick={async () => {
        if (disabled) return 
        setLoading(true)
        await onClick()
        setLoading(false)
      }}
      style={{ ...style, backdropFilter: 'blur(1rem)', boxShadow: '0 0 3rem rgba(0, 0, 0, .05)', textShadow: '2px 2px 2px rgba(0, 0, 0, .07)' }}
      className={`${className} flex justify-center items-center align-middle overflow-hidden outline-none select-none flex-shrink-0 bg-gray-200 rounded-full border-white border border-opacity-20 ${styles[variant || 'default']} ${loading ? 'pointer-events-none' : 'pointer-events-auto'} ${disabled && disabledStyle}`}>
      <span className={`flex justify-center items-center align-middle ${loading ? 'text-transparent' : 'text-white' }`}>
        {Icon && <Icon className={iconStyles[variant || 'default']} style={{ filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, .07))' }} />}
        {children}
      </span>
      {loading &&
        <div className={`absolute w-full h-full p-2 text-white`}>
          <FiLoader className="stroke-1 rotating box-border w-full h-full" />
        </div>
      }
    </button>
  )
}
