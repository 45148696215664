const location = () => typeof window !== `undefined` ? window.location : { pathname: '', hostname: '', search: '' }

export const backUrl = (location().hostname === 'localhost') ? 'https://phoprint-back.wizito.com' : 'https://phoprint-back.wizito.com'
export const cardPaymentUrl = 'http://localhost:9000'
export const printerUrl = 'http://localhost:2000'
const logInterval = 60 * 60 * 1000
const boothId = (location().search.match(/id=(\d+)(&|$)/) || [])[1]
const boothToken = (location().search.match(/token=([^&]+)(&|$)/) || [])[1]
const authHeader = { headers: { 'Authorization': boothToken } }

export function findBooth() {
  return fetch(`${backUrl}/booths/${boothId}`, authHeader)
    .then(res => res.json())
    .then(res => res.error ? { error: `Echec de chargement de la configuration` } : res)
    .catch(_ => ({ error: `Le serveur est inaccessible` }))
}

export async function updateStatus() {
  return fetch(`${backUrl}/booths/${boothId}`, {
    ...authHeader,
    method: 'PUT',
    body: JSON.stringify({ status: { connected: new Date() } })
  })
}
if (boothToken) {
  // setInterval(updateStatus, logInterval)
  updateStatus()
}

export function findSession(id) {
  return fetch(`${backUrl}/sessions/${id}`, authHeader)
    .then(res => res.json())
    .then(res => res.error ? { error: `Aucune session n'a été trouvée` } : res)
    .catch(_ => ({ error: `Le serveur est inaccessible` }))
}

export function findCompany(id, token) {
  // return fetch('https://storage.googleapis.com/lis-photo/dashboard.json')
  // return fetch('https://cache.wizito.com/5668e111ec4c090bfc7351483cd76e09f608429c/refresh', { headers: { Authorization: token } })
  return fetch(`${backUrl}/companies/${id}?token=${token}`)
    .then(res => res.json())
    .then(res => res.error ? { error: `Aucune company n'a été trouvée` } : res)
    .catch(_ => ({ error: `Le serveur est inaccessible` }))
}

export function findSessionByCode(code) {
  return fetch(`${backUrl}/sessions/code/${code}`)
    .then(res => res.json())
    .then(res => res.error ? { error: `Aucune session n'a été trouvée` } : res)
    .catch(_ => ({ error: `Le serveur est inaccessible` }))
}

export function createSession() {
  return fetch(`${backUrl}/sessions/`, { method: 'POST', ...authHeader })
    .then(res => res.json())
}

export function uploadPhoto(code, photos, retryCount = 0) {
  console.log('Uploading', retryCount)
  const form = new FormData()
  photos.forEach(p => form.append('files.photos', p, 'photo.jpg'))
  form.append('data', JSON.stringify({}))
  return fetch(`${backUrl}/sessions/${code}`, { method: 'POST', body: form })
    .then(res => res.json())
    .catch(e => {
      console.log('Upload error', e)
      if (retryCount > 3) return Promise.resolve({ error: 'Erreur d\'upload' })
      return new Promise(resolve =>
        setTimeout(() =>
          resolve(
            uploadPhoto(code, photos, retryCount + 1)
          )
          , 1500)
      )
    })
}

export function applyPromo(code, promo) {
  return fetch(`${backUrl}/sessions/${code}/promo/${promo}`, { method: 'PUT' })
    .then(res => res.json())
    .then(res => res.error ? { error: `Code de réduction invalide` } : res)
}

export function printerStatus() {
  return fetch(printerUrl).then(res => res.json()).catch(e => ({ error: 'Server not found' }))
}

export function print(image64, quantity, media) {
  return fetch(`${printerUrl}/print`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ image: image64, quantity, media })
  })
}

export function pay(amount) {
  return fetch(`${cardPaymentUrl}/${amount}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  }).then(res => res.json())
}

export function createPayment(payment) {
  return fetch(`${backUrl}/payments`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader.headers },
    body: JSON.stringify(payment)
  }).then(res => res.json())
}

export function updatePayment(id, payment) {
  return fetch(`${backUrl}/payments/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader.headers },
    body: JSON.stringify(payment)
  }).then(res => res.json())
}

export function logSession(id, message, more) {
  return fetch(`${backUrl}/sessions/${id}/log`, { method: 'PUT', ...authHeader, body: JSON.stringify({ message, more }) })
}